import { type ChangeEvent, useState } from "react";
import { Box, Checkbox, Link, TextField } from "@cruk/cruk-react-components";

import { useTracking } from "@fwa/src/hooks/useTracking";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import {
  buildForgottenPasswordURL,
  resetPassword,
} from "@fwa/src/services/auth";

import { FormActions } from "@fwa/src/components/FormActions";
import { FormFieldWrapper } from "@fwa/src/components/styles";

import { type OauthUserType } from "@fwa/src/types";

type Props = {
  onSuccess: () => void;
  onCancel?: () => void;
  cancelButtonText?: string;
  submitButtonText?: string;
};

export const ChangePasswordForm = ({
  onSuccess,
  onCancel,
  cancelButtonText,
  submitButtonText,
}: Props) => {
  const { trackError } = useTracking();
  const [fundraiserState, setFundraiserState] = useFundraiserContext();
  const { fundraiser, tempPassword } = fundraiserState;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
  const [currentPasswordCorrect, setCurrentPasswordCorrect] =
    useState<boolean>(true);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword1, setNewPassword1] = useState<string>("");
  const [newPassword2, setNewPassword2] = useState<string>("");
  const [submissionErrorMessage, setSubmissionErrorMessage] =
    useState<string>("");
  // we don't want people to disconnect from facebook unless they have set a password in oauth
  const hasSetPassword = (fundraiser?.oauthUser?.passwordStrength || 0) > 0;
  const firstTimePassword = !hasSetPassword || tempPassword;

  const clearErrors = () => {
    setPasswordsMatch(true);
    setCurrentPasswordCorrect(true);
    setSubmissionErrorMessage("");
  };

  const reset = () => {
    clearErrors();
    setOldPassword("");
    setNewPassword1("");
    setNewPassword2("");
  };

  const handleSubmitOfChangePassword = () => {
    clearErrors();
    setIsLoading(true);

    if (!newPassword1.length || !newPassword2.length) {
      setPasswordsMatch(false);
      setSubmissionErrorMessage("Please enter both passwords");
      setIsLoading(false);
      return;
    }

    if (newPassword1.length < 8) {
      setSubmissionErrorMessage("Password must be 8 characters or longer");
      setPasswordsMatch(false);
      setIsLoading(false);
      return;
    }

    if (newPassword1 !== newPassword2) {
      setPasswordsMatch(false);
      setSubmissionErrorMessage("Passwords must match");
      setIsLoading(false);
      return;
    }

    if (!firstTimePassword && !oldPassword.length) {
      setPasswordsMatch(false);
      setSubmissionErrorMessage("Please enter current password");
      setIsLoading(false);
      return;
    }

    if (!firstTimePassword) {
      resetPassword(oldPassword, newPassword1)
        .then((res: Response) => {
          if (!res.ok) {
            if (res.status === 401) {
              setCurrentPasswordCorrect(false);
              setSubmissionErrorMessage("Current password incorrect");
            } else {
              setSubmissionErrorMessage(
                "Please check your existing password and ensure your new password is sufficiently complex",
              );
            }
            throw new Error("Unable to change password");
          }
          return undefined;
        })
        .then(() => {
          reset();
          setIsLoading(false);
          if (onSuccess) {
            onSuccess();
          }
          return undefined;
        })
        .catch((err) => {
          trackError(err as Error, { component: "AuthenticationForm" });
          setIsLoading(false);
        });
    } else {
      resetPassword(tempPassword, newPassword1)
        .then((res: Response) => {
          if (!res.ok) {
            setSubmissionErrorMessage(
              "Please check your new password and ensure your new password is sufficiently complex",
            );
            throw new Error("Unable to set password");
          }
          return res.json();
        })
        .then((oauthUser: OauthUserType) => {
          if (fundraiserState.fundraiser) {
            setFundraiserState({
              ...fundraiserState,
              fundraiser: {
                ...fundraiserState.fundraiser,
                oauthUser,
              },
              tempPassword: null,
            });
          }
          reset();
          setIsLoading(false);
          if (onSuccess) {
            onSuccess();
          }
          return undefined;
        })
        .catch((err) => {
          trackError(err as Error, { component: "AuthenticationForm" });
          setIsLoading(false);
        });
    }
  };

  const handleShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div data-component="password-form">
      <Box marginBottom="l">
        <form>
          <FormFieldWrapper>
            {firstTimePassword ? null : (
              <TextField
                label="Current password"
                type={showPassword ? "text" : "password"}
                required
                hintText={
                  <Link
                    href={buildForgottenPasswordURL()}
                    data-cta-type="link-reset-password"
                  >
                    Forgotten your password?
                  </Link>
                }
                onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                  setOldPassword(changeEvent.target.value);
                }}
                value={oldPassword}
                errorMessage={
                  !currentPasswordCorrect ? submissionErrorMessage : ""
                }
                hasError={
                  !currentPasswordCorrect && !!submissionErrorMessage.length
                }
                minLength={8}
              />
            )}
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              required
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setNewPassword1(changeEvent.target.value);
              }}
              value={newPassword1}
              hasError={!passwordsMatch}
              minLength={8}
            />
            <TextField
              label="Confirm password"
              type={showPassword ? "text" : "password"}
              required
              value={newPassword2}
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setNewPassword2(changeEvent.target.value);
              }}
              errorMessage={
                currentPasswordCorrect ? submissionErrorMessage : ""
              }
              hasError={
                currentPasswordCorrect && !!submissionErrorMessage.length
              }
              minLength={8}
            />
            <Checkbox
              value="Show Passwords"
              checked={showPassword}
              onChange={handleShowPasswordChange}
            />
          </FormFieldWrapper>
        </form>
      </Box>
      <FormActions
        submitButtonType="button"
        isLoading={isLoading}
        onSubmit={handleSubmitOfChangePassword}
        onCancel={onCancel || null}
        cancelLabel={cancelButtonText}
        submitLabel={submitButtonText}
      />
    </div>
  );
};

export default ChangePasswordForm;
