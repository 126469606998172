import React, { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/compat/router";
import {
  Box,
  Button,
  Heading,
  Text,
  Collapse,
  Modal,
} from "@cruk/cruk-react-components";

import { useTracking } from "@fwa/src/hooks/useTracking";
import { fetcher, FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useModalContext } from "@fwa/src/contexts/ModalContext";
import { getTokenFromAuthCode, logIn, challenge } from "@fwa/src/services/auth";
import { getLoggedInFundraiser } from "@fwa/src/services/fundraiserService";

import { ChangePasswordForm } from "@fwa/src/components/ChangePasswordForm";

import { RowCenter } from "@fwa/src/components/styles";
import {
  PartyPopper,
  MessageWrapper,
} from "@fwa/src/components/ActivityManagementJourney/styles";

import {
  type FinaliseRegistrationType,
  type FundraiserType,
  type FundraisingPageType,
  type OneTimeLoginType,
} from "@fwa/src/types";

const OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID || "";
const OAUTH_BASE_URL = process.env.NEXT_PUBLIC_OAUTH_BASE_URL || "";

const modalPartyPopper = "/assets/images/img/party-popper.svg";

export const ActivityManagementJourney = ({
  page,
}: {
  page: FundraisingPageType;
}) => {
  const { trackError, trackEventGtm } = useTracking();
  const [, setModalState] = useModalContext();
  const [fundraiserState, setFundraiserState] = useFundraiserContext();
  const router = useRouter();
  const {
    message_header: messageHeader,
    message_body: messageBody,
    signature,
    ...query
  } = router?.query || {};
  const [finaliseRegistration, setFinaliseRegistration] = useState<
    FinaliseRegistrationType | undefined
  >();
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [hasRegFailed, setHasRegFailed] = useState<boolean>(false);
  const [isDismissing, setIsDismissing] = useState<boolean>(false);

  // Before AM updates their backend infrastructure the message header and body are still base64 encoded and gzipped
  // for this reason until the update to using plain strings in the query string will will use some default text
  // message_signature is deprecated in the new API, if it exists we are seeing the legacy AM API.
  const isAMLegacy = typeof query.message_signature !== "undefined";
  const defaultHeader = `Congratulations! You're now signed up.`;
  const defaultBody = `Personalise your page to let everyone know why you're raising money and share it with your friends and family to encourage donations. Why not be the first to add a donation to your page? It could encourage others to do the same!`;
  const header = isAMLegacy ? defaultHeader : messageHeader || "";
  const body = isAMLegacy ? defaultBody : messageBody || "";

  const { loggedInStatus, fundraiser, tempPassword } = fundraiserState;
  const loggedInAsNonRegisteredUser =
    page?.fundraiser &&
    fundraiser &&
    fundraiser?.uniqueId !== page.fundraiser.uniqueId;
  const showLoginLink =
    loggedInStatus === "loggedOut" || loggedInAsNonRegisteredUser;

  const dismissModal = useCallback(() => {
    // flag to stop infinite loop when dismiss triggers infinite loop when we update the query string
    setIsDismissing(true);
    router
      ?.push({ query }, undefined, {
        shallow: true,
      })
      .then(() => setModalState(null))
      .catch((err) => {
        trackError(err as Error, { component: "ActivityManagementJourney" });
      });
  }, [router, query, setModalState, trackError]);

  // first complete registration and set registration data to state
  useEffect(() => {
    if (
      !signature ||
      isRegistering ||
      finaliseRegistration ||
      hasRegFailed ||
      !router
    )
      return;
    (async () => {
      setIsRegistering(true);

      // get registration details from signature
      const registration: FinaliseRegistrationType | void =
        await fetcher<FinaliseRegistrationType>(
          `${FWS_BASE_URL}/fundraisingpages/${page.uniqueId}/finaliseactivityregistration`,
          {
            method: "POST",
            body: JSON.stringify({
              codeChallenge: challenge(),
              clientId: OAUTH_CLIENT_ID,
              signature,
            }),
          },
        ).catch((err) => {
          trackError(err as Error, {
            component: "ActivityManagementJourney",
          });
          setIsRegistering(false);
          setHasRegFailed(true);
          return;
        });
      if (!registration) {
        const err = new Error("Failed to register for activity");
        setIsRegistering(false);
        trackError(err as Error, {
          component: "ActivityManagementJourney",
        });
        setIsRegistering(false);
        setHasRegFailed(true);
        return;
      }

      // do one time log in if on registration
      if (registration?.oneTimeLogin) {
        const oneTimeLogin: OneTimeLoginType | void =
          await fetcher<OneTimeLoginType>(
            `${OAUTH_BASE_URL}${registration.oneTimeLogin.urlPath}?noninteractive=true`,
            {
              credentials: "include",
            },
          ).catch((err) => {
            trackError(err as Error, {
              component: "ActivityManagementJourney",
            });
          });

        if (oneTimeLogin) {
          await getTokenFromAuthCode(oneTimeLogin.authCode);
          const loggedInFundraiser: FundraiserType | void | null =
            await getLoggedInFundraiser()
              .then((res) => res)
              .catch((err) => {
                trackError(err as Error, {
                  component: "ActivityManagementJourney",
                });
              });

          if (loggedInFundraiser) {
            setFundraiserState({
              fundraiser: loggedInFundraiser,
              loggedInStatus: "loggedIn",
              tempPassword: registration.oneTimeLogin?.password || null,
            });
          }
        }
      }
      // update query string to remove signature update state
      await router?.push(
        {
          pathname: router.pathname,
          query: { ...router.query, signature: undefined },
        },
        undefined,
        { shallow: true },
      );

      trackEventGtm({
        event: "am_signup_confirmation",
        activityName: page.activityName,
      });
      // state update riggers the use effect that sets the modal to show
      setFinaliseRegistration(registration);
      setIsRegistering(false);
    })();
  }, [
    // these change and we check
    isRegistering,
    finaliseRegistration,
    hasRegFailed,
    signature,
    page,
    query,
    router,
    // below are dependencies set on mount
    trackEventGtm,
    trackError,
    setFundraiserState,
  ]);

  // if registered, attempt to log in as registered user
  // show activity management modal based on registration state
  useEffect(() => {
    if (
      isDismissing ||
      !(finaliseRegistration || hasRegFailed) ||
      loggedInStatus === "unknown"
    ) {
      return;
    }

    setModalState(
      <Modal
        closeFunction={() => setModalState(null)}
        modalName="page infomation"
      >
        <MessageWrapper data-component="am-modal">
          <Heading textAlign="center">{header}</Heading>
          <PartyPopper
            alt=""
            width="90px"
            height="90px"
            src={modalPartyPopper}
          />
          <Text textAlign="center">{body}</Text>

          {tempPassword ? (
            <>
              <Text textAlign="center">
                Please create a password to continue.
              </Text>
              <ChangePasswordForm
                onSuccess={dismissModal}
                onCancel={dismissModal}
                cancelButtonText="Remind me later"
                submitButtonText="Submit"
              />
              <Box marginTop="m">
                <Collapse
                  headerTitleText="Why are we asking for this?"
                  id="password-explanation"
                >
                  <Text marginBottom="m">
                    {`We've created a personalised Giving Page for you which
                      will help you fundraise for your event.`}
                  </Text>
                  <Text marginBottom="m">
                    {` As there wasn't an existing account linked to the email
                      address provided, we've created this one for you. It's
                      important that you create a password now, so you can
                      easily log in to manage your Giving Page during your
                      fundraising.`}
                  </Text>
                </Collapse>
              </Box>
            </>
          ) : (
            <>
              {showLoginLink && (
                <Box marginBottom="xs">
                  <RowCenter>
                    <Button
                      onClick={() => {
                        logIn();
                      }}
                      data-cta-type="log-in"
                    >
                      Log in to edit page
                    </Button>
                  </RowCenter>
                </Box>
              )}
              <Box marginBottom="xs">
                <RowCenter>
                  <Button
                    appearance={showLoginLink ? "secondary" : "primary"}
                    onClick={() => {
                      dismissModal();
                    }}
                    data-cta-type="dismiss-modal"
                  >
                    Explore my page
                  </Button>
                </RowCenter>
              </Box>
            </>
          )}
        </MessageWrapper>
      </Modal>,
    );
  }, [
    finaliseRegistration,
    hasRegFailed,
    showLoginLink,
    loggedInStatus,
    tempPassword,
    router, // querystring is cleaned when someone dismisses the modal triggering a rerender
    body,
    header,
    isDismissing, // so we check a flag if dismissing to stop modal opening infinitely
    dismissModal,
    setModalState,
    setIsDismissing,
    setFinaliseRegistration,
    trackError,
  ]);

  return null;
};

export default ActivityManagementJourney;
